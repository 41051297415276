import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { environment } from '../../../../environments/environment'
import { Role } from '../../../auth/auth-role.enum'
import { AuthService, IAuthStatus } from '../../../auth/auth.service'
import { ISignInOutWS } from './sign-inout'

export interface ISignInOutService {
  getSignInOut(date, event): Observable<ISignInOutWS>
  getCurrentSignInOut(date, event): Observable<ISignInOutWS>
  getSignInOutReport(month, year, user): Observable<ISignInOutWS>
  getAllSignInOutReport(month, year): Observable<ISignInOutWS>
  setSignIn(date, event, time): Observable<ISignInOutWS>
  setSignOut(date, event, time): Observable<ISignInOutWS>
}

@Injectable({
  providedIn: 'root',
})
export class SignInOutService implements ISignInOutService {
  private currentAuthStatus: IAuthStatus
  constructor(private httpClient: HttpClient, private authService: AuthService) {
    this.authService.authStatus.subscribe(
      (authStatus) => (this.currentAuthStatus = authStatus)
    )
  }

  getSignInOutReport(month: string, year: string, user: string) {
    let e: string = ''
    if (this.currentAuthStatus.userRole == Role.Developer) e = 'report'
    else e = 'all'
    return this.httpClient.post<ISignInOutWS>(
      `${environment.baseUrl}/api/user/getsigninouts`,
      {
        event: e,
        month: month,
        year: year,
        user: user,
        qs: Math.floor(new Date().getTime() / 1000).toString(),
      }
    )
  }

  getAllSignInOutReport(month: string, year: string) {
    return this.httpClient.post<ISignInOutWS>(
      `${environment.baseUrl}/api/user/getallsigninout`,
      {
        month: month,
        year: year,
        qs: Math.floor(new Date().getTime() / 1000).toString(),
      }
    )
  }

  getCurrentSignInOut(date: string, event: string) {
    return this.httpClient.post<ISignInOutWS>(
      `${environment.baseUrl}/api/user/getcurrentsigninout`,
      {
        event: event,
        date: date,
        qs: Math.floor(new Date().getTime() / 1000).toString(),
      }
    )
  }

  getSignInOut(date: string, event: string) {
    return this.httpClient.get<ISignInOutWS>(
      `${environment.baseUrl}/api/user/signinoutsdetails`,
      {
        params: {
          event: event,
          date: date,
          qs: Math.floor(new Date().getTime() / 1000).toString(),
        },
      }
    )
  }

  setSignIn(date: string, event: string, time: string) {
    return this.httpClient.post<ISignInOutWS>(
      `${environment.baseUrl}/api/user/signinouts`,
      {
        date: date,
        event: event,
        time: time,
        qs: Math.floor(new Date().getTime() / 1000).toString(),
      }
    )
  }

  setSignOut(date: string, event: string, time: string) {
    return this.httpClient.post<ISignInOutWS>(
      `${environment.baseUrl}/api/user/signinouts_update`,
      {
        date: date,
        event: event,
        time: time,
        qs: Math.floor(new Date().getTime() / 1000).toString(),
      }
    )
  }
}
