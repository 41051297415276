import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable, throwError as observableThrowError } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { LoaderService } from '../services/loader.service'
import { Role } from './auth-role.enum'
import { AuthService } from './auth.service'

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private loaderService: LoaderService
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const jwt = this.authService.getToken()
    this.loaderService.show()
    const authRequest = req.clone({
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json',  //ashna
        // 'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${jwt}`,
      }),
    })
    return next.handle(authRequest).pipe(
      catchError((err, caught) => {
        if (this.router.url == '/' || this.router.url.includes('user/login')) {
          throw err.error.message
        } else if (err.status === 401) {
          this.authService.authStatus.next({
            isAuthenticated: false,
            userRole: Role.None,
            userId: '',
            userName: '',
          })
          this.router.navigate(['/user/login'], {
            queryParams: { redirectUrl: this.router.routerState.snapshot.url },
          })
        }
        return observableThrowError(err)
      })
    )
  }
}
