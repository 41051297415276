import { HttpErrorResponse } from '@angular/common/http'
import { throwError } from 'rxjs'

export function transformError(error: HttpErrorResponse) {
  let errorMessage = 'An unknown error has occurred'
  if (typeof error === 'string') {
    errorMessage = error
  } else if (error.error instanceof ErrorEvent) {
    errorMessage = `Error! ${error.error.message}`
  } else if (error.status) {
    errorMessage = `Request failed with ${error.status} ${error.statusText}`
  }
  return throwError(errorMessage)
}

export function getYears() {
  let _years: { id: string; description: string }[]
  return (_years = [
    { id: '2018', description: '2018' },
    { id: '2019', description: '2019' },
    { id: '2020', description: '2020' },
    { id: '2021', description: '2021' },
    { id: '2022', description: '2022' },
    { id: '2023', description: '2023' },
  ])
}

export function getMonths() {
  let _months: { id: string; description: string }[]
  return (_months = [
    { id: '01', description: 'January' },
    { id: '02', description: 'February' },
    { id: '03', description: 'March' },
    { id: '04', description: 'April' },
    { id: '05', description: 'May' },
    { id: '06', description: 'June' },
    { id: '07', description: 'July' },
    { id: '08', description: 'August' },
    { id: '09', description: 'September' },
    { id: '10', description: 'October' },
    { id: '11', description: 'November' },
    { id: '12', description: 'December' },
  ])
}
