import { DatePipe } from '@angular/common'
import { Component } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { of, Subscription } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import { Role } from '../../auth/auth-role.enum'
import { AuthService, IAuthStatus } from '../../auth/auth.service'
import { ISignInOut, ISignInOutWS } from '../../services/api/sign-inout/sign-inout'
import { SignInOutService } from '../../services/api/sign-inout/sign-inout.service'
import { LoaderService } from '../../services/loader.service'
import { UiService } from '../../services/ui.service'

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.css'],
})
export class NavigationMenuComponent {
  public _isManager: boolean = false
  public _isSales: boolean = false
  public _userRole: string = ''
  public _showSignInOut: boolean = false
  public _currentDate: string = ''
  public _currentDay: string = ''
  public _currentMonth: string = ''
  public _currentYear: string = ''
  public _isLoading: boolean = false
  public _signInOutData: ISignInOut = {
    SIGNIN_TIME: '0:0',
    SIGNOUT_TIME: '00:00',
    EVENTDATE: '',
    Name: '',
    USERID: 0,
    TotalMins: 0,
    tminsformated: '',
    TotalTaskMins: 0,
    ttaskminsformatted: '',
  }
  public _currentRoutePath: string = ''
  public _toggleUserNav: boolean = true

  _authStatusSubscription: Subscription

  constructor(
    public _authService: AuthService,
    private _signinoutService: SignInOutService,
    private _uiService: UiService,
    private _loaderService: LoaderService,
    private _router: Router
  ) {
    const dt = new DatePipe('en-US')
    this._currentDate = dt.transform(new Date(), 'yyyy-MM-dd')
    this._currentDay = dt.transform(new Date(), 'dd')
    this._currentMonth = dt.transform(new Date(), 'MMM')
    this._currentYear = dt.transform(new Date(), 'y')

    this._router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this._currentRoutePath = val.url
      }
    })

    this._authStatusSubscription = this._authService.authStatus
      .pipe(
        map((data: IAuthStatus) => {
          return data as IAuthStatus
        }),
        catchError((err) => {
          return of([])
        })
      )
      .subscribe((data) => {
        const d = data as IAuthStatus
        if (d.userRole == Role.Manager) this._isManager = true
        if (d.userRole == Role.Developer) {
          this._isLoading = true
          this.onSignInOutRefresh()
          this._showSignInOut = true
        }
        if (d.userRole == Role.Sales) this._isSales = true
        this._userRole = d.userRole
      })
  }
  get isShowSignOut() {
    return this._showSignInOut
  }
  onSignInOutRefresh() {
    return this._signinoutService
      .getCurrentSignInOut(this._currentDate, '')
      .pipe(
        map((data: ISignInOutWS) => {
          this._loaderService.hide()
          return data.data[0] as ISignInOut
        }),
        catchError((err) => {
          this._isLoading = false
          this._loaderService.hide()
          return of([])
        })
      )
      .subscribe((data) => {
        this._isLoading = false
        if (data != undefined) {
          this._signInOutData = data as ISignInOut
        }
      })
  }

  onSignInOut(EVENT: string) {
    const d = new DatePipe('en-US')
    const currentTime = d.transform(new Date(), 'HH:mm')
    this._loaderService.show()
    if (EVENT == 'SIGNOUT') {
      //different signout api here
      return this._signinoutService
        .setSignOut(this._currentDate, EVENT, currentTime)
        .pipe(
          map((data: ISignInOutWS) => {
            this._loaderService.hide()
            if (data.status_code == 500) this._uiService.showToast(data.message, 2000)
            this._signInOutData = data.data[0] as ISignInOut
            return data.data[0] as ISignInOut
          }),
          catchError((err) => {
            this._isLoading = false
            this._loaderService.hide()
            return of([])
          })
        )
        .subscribe((data) => {
          this._isLoading = false
          if (data != undefined) {
            this._signInOutData = data as ISignInOut
          }
        })
    } else {
      return this._signinoutService
        .setSignIn(this._currentDate, EVENT, currentTime)
        .pipe(
          map((data: ISignInOutWS) => {
            this._loaderService.hide()
            if (data.status_code == 500) this._uiService.showToast(data.message, 2000)
            return data.data[0] as ISignInOut
          }),
          catchError((err) => {
            this._isLoading = false
            this._loaderService.hide()
            return of([])
          })
        )
        .subscribe((data) => {
          this._isLoading = false
          if (data != undefined) {
            this._signInOutData = data as ISignInOut
          }
        })
    }
  }

  onSignInOutMenu() {
    this._router.navigate(['/manager/sign-inout'])
  }
  onTaskList() {
    this._router.navigate(['/manager/task-list'])
  }
  onLeaveList() {
    this._router.navigate(['/manager/leave-list'])
  }
  onDashBoard() {
    if (this._userRole == Role.Sales) this._router.navigate(['/sales/user-dashboard'])
    else this._router.navigate(['/manager/user-dashboard'])
  }
  onUsers() {
    this._router.navigate(['/manager/user-list'])
  }
  onProjection() {
    this._router.navigate(['/sales/projection-list'])
  }
  onProjects() {
    this._router.navigate(['/manager/project-list'])
  }
  onClients() {
    this._router.navigate(['/manager/client-list'])
  }
  onLeads() {
    this._router.navigate(['/manager/lead-list'])
  }
  onCompanies() {
    this._router.navigate(['/manager/companies-list'])
  }
  onInvoices() {
    this._router.navigate(['/manager/invoice-list'])
  }
  onLogout() {
    this._authService.logout()
    this._router.navigateByUrl('/')
  }

  ngOnDestroy() {
    this._authStatusSubscription.unsubscribe()
  }
}
