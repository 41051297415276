import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import * as decode from 'jwt-decode'
import { BehaviorSubject, Observable } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import { environment } from '../../environments/environment'
import { transformError } from '../common/common'
import { CacheService } from '../services/cache.service'
import { UiService } from '../services/ui.service'
import { Role } from './auth-role.enum'

export interface IAuthService {
  authStatus: BehaviorSubject<IAuthStatus>
  login(email: string, password: string): Observable<IAuthStatus>
  logout()
  getToken(): string
}

export interface IAuthStatus {
  isAuthenticated: boolean
  userRole: Role
  userId: string
  userName: string
}

interface IServerAuthResponse {
  status: string
  message: string
  data: {
    user: {
      ID: Number
      Name: string
    }
    token: string
  }
}

export const defaultAuthStatus = {
  isAuthenticated: false,
  userRole: Role.None,
  userId: null,
  userName: null,
}

@Injectable({
  providedIn: 'root',
})
export class AuthService extends CacheService implements IAuthService {
  authStatus = new BehaviorSubject<IAuthStatus>(
    this.getItem('authStatus') || defaultAuthStatus
  )

  userDetails: BehaviorSubject<{
    ID: Number
    Name: string
  }> = new BehaviorSubject<any>({
    ID: null,
    Name: '',
  })

  uiService: any
  constructor(private httpClient: HttpClient, uiService: UiService) {
    super()
    this.authStatus.subscribe((authStatus) => this.setItem('authStatus', authStatus))
    let username = this.getItem<string>('username')
    let userId = this.getItem<string>('userid')
    this.userDetails.next({
      ID: Number.parseInt(userId),
      Name: username,
    })
    this.uiService = uiService
  }

  login(email: string, password: string): Observable<IAuthStatus> {
    return this.httpClient
      .post<IServerAuthResponse>(`${environment.baseUrl}/api/user/login`, {
        email: email,
        password: password,
      })
      .pipe(
        map((value) => {
          if (value.status == 'Error') {
            this.uiService.showToast(value.status + ': ' + value.message)
            return defaultAuthStatus as IAuthStatus
          } else {
            const data = atob(value.data.token.split('.')[1])
            const d = JSON.parse(data)

            this.setToken(value.data.token)
            this.setUserId(value.data.user.ID.toString())
            this.setUsername(value.data.user.Name)

            const retAuth = {
              isAuthenticated: true,
              userRole:
                d.role == '1'
                  ? Role.Developer
                  : d.role == '0'
                  ? Role.Manager
                  : Role.Sales,
              userId: value.data.user.ID.toString(),
              userName: value.data.user.Name,
            } as IAuthStatus
            this.userDetails.next(value.data.user)
            this.authStatus.next(retAuth)
            return retAuth
          }
        }),
        catchError(transformError)
      )
  }

  logout() {
    this.removeItem('users')
    this.removeItem('clients')
    this.clear()
    this.clearToken()
    this.authStatus.next(defaultAuthStatus)
  }

  private setToken(jwt: string) {
    this.setItem('jwt', jwt)
  }

  private setUsername(username: string) {
    this.setItem('username', username)
  }

  private setUserId(userid: string) {
    this.setItem('userid', userid)
  }

  private getDecodedToken(): IAuthStatus {
    return decode(this.getItem('jwt'))
  }

  getToken(): string {
    return this.getItem('jwt') || ''
  }

  private clearToken() {
    this.removeItem('jwt')
  }
}
