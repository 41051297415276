import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-page-not-found',
  template: `
    <div class="row">
      <div class="col-12 text-center ">
        This page doesn't exist. Go back to <a routerLink="/">home</a>.
      </div>
    </div>
  `,
})
export class PageNotFoundComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
