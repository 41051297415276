import { Component, OnInit, ViewChild } from '@angular/core'
import { MediaObserver } from '@angular/flex-layout'
import { MatSidenav } from '@angular/material/sidenav'
import { Router } from '@angular/router'

import { AuthService } from './auth/auth.service'
import { LoaderService } from './services/loader.service'

@Component({
  selector: 'app-root',
  styles: [
    `
      .app-container {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
      .app-toolbar {
        position: fixed;
        z-index: 2;
        font-size: 16px;
        padding-left: 10px !important;
        padding-right: 15px !important;
      }
      .progress_bar {
        z-index: 20;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
      }

      .app-sidenav-container {
        flex: 1 0 auto;
      }
      mat-sidenav {
        width: 250px;
      }
    `,
  ],
  template: `
    <mat-progress-bar
      color="warn"
      mode="indeterminate"
      class="progress_bar"
      *ngIf="_isLoading"
    ></mat-progress-bar>
    <div class="app-container">
      <mat-toolbar
        color="primary"
        *ngIf="_isAuthenticated"
        fxLayoutGap="8px"
        class="app-toolbar"
        [class.app-is-mobile]="media.isActive('xs')"
      >
        <!-- <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button> -->
        <app-navigation-menu></app-navigation-menu>
      </mat-toolbar>

      <mat-sidenav-container class="app-sidenav-container page-wrapper">
        <mat-sidenav
          opened="false"
          #sidenav
          [mode]="media.isActive('xs') ? 'over' : 'side'"
          [fixedInViewport]="media.isActive('xs')"
          fixedTopGap="56"
        >
          <app-navigation-menu></app-navigation-menu>
        </mat-sidenav>
        <mat-sidenav-content>
          <router-outlet></router-outlet>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </div>
  `,
})
export class AppComponent implements OnInit {
  public _isLoading = false
  public _userDetails: string = ''
  @ViewChild('sidenav')
  public sideNav: MatSidenav
  public _isAuthenticated = false

  constructor(
    private _loaderService: LoaderService,
    private authService: AuthService,
    public media: MediaObserver,
    private _router: Router
  ) {
    this._loaderService.loaderState$.subscribe((data) =>
      setTimeout(() => {
        this._isLoading = data
      }, 0)
    )
  }

  ngOnInit() {
    this.authService.authStatus.subscribe((authStatus) => {
      setTimeout(() => {
        this._isAuthenticated = authStatus.isAuthenticated
        if (!authStatus.isAuthenticated) this.sideNav.close()
        else {
          this._userDetails =
            'Welcome ' + authStatus.userName + ' [ ' + authStatus.userRole + ' ] '
          this.sideNav.opened = false
        }
      }, 0)
    })
  }

  onLogout() {
    this.authService.logout()
    this._router.navigateByUrl('/')
  }
}
