import { NgxMatFileInputModule } from '@angular-material-components/file-input'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { DateAdapter, MatNativeDateModule } from '@angular/material/core'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatTooltipModule } from '@angular/material/tooltip'

import { DateFormat } from './common/dateformat'

@NgModule({
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatDialogModule,
    MatSidenavModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatNativeDateModule,
    MatIconModule,
    MatCardModule,
    MatListModule,
    MatGridListModule,
    MatInputModule,
    MatMenuModule,
    NgxMatFileInputModule,
  ],
  exports: [
    MatButtonModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatDialogModule,
    MatSidenavModule,
    MatNativeDateModule,
    MatCardModule,
    MatIconModule,
    MatTooltipModule,
    MatListModule,
    MatGridListModule,
    MatInputModule,
    MatMenuModule,
    NgxMatFileInputModule,
  ],
  providers: [{ provide: DateAdapter, useClass: DateFormat }],
})
export class AppMaterialModule {}
