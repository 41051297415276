<!--




<div class="user-profile" style="background: url(assets/img/sign-bg.jpg) no-repeat;">
    <h3 class="head_time">{{_currentDate}}{{(_authService.authStatus | async).userRole}}</h3>
    <div *ngIf="((_authService.authStatus | async).userRole == 'developer')">
        <div class="btn-signin">
            <div *ngIf="_signInOutData.signintime == '0:0'">
                <button mat-fab type="submit" color="primary" (click)="onSignInOut('SIGNIN')">Sign In</button>
            </div>
            <div *ngIf="_signInOutData.signintime != '0:0' && _signInOutData.signouttime == '0:0'">
                <button mat-fab type="submit" color="warn" (click)="onSignInOut('SIGNOUT')">Sign Out</button>
            </div>
        </div>
        <h3 class="head_time">
            <div *ngIf="_signInOutData.signintime != '0:0'">Signed In : <b>{{_signInOutData.signintime}}</b></div>
            <div *ngIf="_signInOutData.signouttime != '0:0'">Signed Out : <b>{{_signInOutData.signouttime}}</b></div>
        </h3>
    </div>
</div>

<div class="app-container">
    <div class="side_bar_nav">
        <mat-nav-list>
            <a mat-list-item routerLinkActive="active-link" (click)="onDashBoard()">
                <mat-icon matListIcon>apps</mat-icon> Dashboard</a>
            <a *ngIf="((_authService.authStatus | async).userRole != 'sales')" mat-list-item routerLinkActive="active-link" (click)="onSignInOutMenu()">
                <mat-icon matListIcon>lock_open</mat-icon> Sign In / Out</a>
            <a *ngIf="((_authService.authStatus | async).userRole != 'sales')" mat-list-item routerLinkActive="active-link" (click)="onTaskList()">
                <mat-icon matListIcon>dvr</mat-icon> Task(s)</a>
            <a *ngIf="((_authService.authStatus | async).userRole != 'sales')" mat-list-item routerLinkActive="active-link" (click)="onLeaveList()">
                <mat-icon matListIcon>how_to_reg</mat-icon> Leave(s)</a>

            <h3 matSubheader>Management</h3>
            <a *ngIf="((_authService.authStatus | async).userRole == 'manager')" mat-list-item routerLinkActive="active-link " (click)="onUsers() ">
                <mat-icon matListIcon>account_circle</mat-icon>Users</a>
            <a *ngIf="((_authService.authStatus | async).userRole != 'developer')" mat-list-item routerLinkActive="active-link " (click)="onClients() ">
                <mat-icon matListIcon>how_to_reg</mat-icon> Clients</a>
            <a *ngIf="((_authService.authStatus | async).userRole != 'developer')" mat-list-item routerLinkActive="active-link " (click)="onProjects() ">
                <mat-icon matListIcon>list_alt</mat-icon>Projects</a>
            <a *ngIf="((_authService.authStatus | async).userRole != 'developer')" mat-list-item routerLinkActive="active-link " (click)="onProjection() ">
                <mat-icon matListIcon>trending_up</mat-icon>Projections</a>
        </mat-nav-list>
    </div>
</div>

<span class="spacer"></span>
<small>{{this._userDetails}}</small>
<button mat-button (click)="onLogout()"
  matTooltip="Logout" aria-label="Logout"><mat-icon>lock_open</mat-icon> Logout
</button>

-->

<div class="navbar" [ngClass]="{ user_nav_show: !_toggleUserNav }">

  <div class="user-logo">
    <img src="assets/img/logo-login-dark.png" alt="homepage">
  </div>


  <div class="user-nav-wrap-overlay" (click)="_toggleUserNav=!_toggleUserNav"></div>
  <div class="user-nav-wrap">
    <div class="navmenu">
      <mat-nav-list>
        <a [ngClass]="{ active : _currentRoutePath.includes('dashboard') }" mat-list-item routerLinkActive="active-link"
          (click)="onDashBoard()">
          <mat-icon matListIcon>apps</mat-icon> <span class="text ">Dashboard</span>
        </a>
        <a [ngClass]="{ active : _currentRoutePath.includes('sign-inout') }"
          *ngIf="((_authService.authStatus | async).userRole !='sales' ) " mat-list-item routerLinkActive="active-link "
          (click)="onSignInOutMenu() ">
          <mat-icon matListIcon>lock_open</mat-icon> <span class="text">Sign In / Out</span>
        </a>
        <a [ngClass]="{ active : _currentRoutePath.includes('task') }"
          *ngIf="((_authService.authStatus | async).userRole != 'sales')" mat-list-item routerLinkActive="active-link"
          (click)="onTaskList()">
          <mat-icon matListIcon>dvr</mat-icon> <span class="text ">Tasks</span>
        </a>
        <a [ngClass]="{ active : _currentRoutePath.includes('leave') }"
          *ngIf="((_authService.authStatus | async).userRole !='sales' ) " mat-list-item routerLinkActive="active-link "
          (click)="onLeaveList() ">
          <mat-icon matListIcon>how_to_reg</mat-icon> <span class="text">Leaves</span>
        </a>
        <a [ngClass]="{ active : _currentRoutePath.includes('user') && !_currentRoutePath.includes('dashboard') }"
          *ngIf="((_authService.authStatus | async).userRole == 'manager')" mat-list-item
          routerLinkActive="active-link " (click)="onUsers() ">
          <mat-icon matListIcon>account_circle</mat-icon><span class="text ">Users</span>
        </a>
        <a [ngClass]="{ active : _currentRoutePath.includes('client') }"
          *ngIf="((_authService.authStatus | async).userRole !='developer' ) " mat-list-item
          routerLinkActive="active-link " (click)="onClients() ">
          <mat-icon matListIcon>how_to_reg</mat-icon> <span class="text">Clients</span>
        </a>
        <a [ngClass]="{ active : _currentRoutePath.includes('project') }"
          *ngIf="((_authService.authStatus | async).userRole != 'developer')" mat-list-item
          routerLinkActive="active-link " (click)="onProjects() ">
          <mat-icon matListIcon>list_alt</mat-icon><span class="text ">Projects</span>
        </a>
        <a [ngClass]="{ active : _currentRoutePath.includes('projections') }"
          *ngIf="((_authService.authStatus | async).userRole !='developer' ) " mat-list-item
          routerLinkActive="active-link " (click)="onProjection() ">
          <mat-icon matListIcon>trending_up</mat-icon><span class="text">Projections</span>
        </a>
        <a [ngClass]="{ active : _currentRoutePath.includes('leads') }"
          *ngIf="((_authService.authStatus | async).userRole !='developer' ) " mat-list-item
          routerLinkActive="active-link " (click)="onLeads() ">
          <mat-icon matListIcon>trending_up</mat-icon><span class="text">Leads</span>
        </a>
        <a [ngClass]="{ active : _currentRoutePath.includes('companies') }"
          *ngIf="((_authService.authStatus | async).userRole !='developer' ) " mat-list-item
          routerLinkActive="active-link " (click)="onCompanies() ">
          <mat-icon matListIcon>trending_up</mat-icon><span class="text">Companies</span>
        </a>
        <a [ngClass]="{ active : _currentRoutePath.includes('invoice') }"
          *ngIf="((_authService.authStatus | async).userRole !='developer' ) " mat-list-item
          routerLinkActive="active-link " (click)="onInvoices() ">
          <mat-icon matListIcon>trending_up</mat-icon><span class="text">Invoice</span>
        </a>
      </mat-nav-list>
    </div>
    <div class="spacer"></div>

    <div class="user-status">


      <ng-container *ngIf="((_authService.authStatus | async).userRole == 'developer')">

        <button class="user-status-btn" mat-button [matMenuTriggerFor]="signInOut">

          <div class="date">
            <div class="day">{{_currentDay}}</div>
            <div class="month-year">
              <div class="month">{{_currentMonth}}</div>
              <div class="year">{{_currentYear}}</div>
            </div>
          </div>

          <div class="divider">&middot;</div>

          <ng-container *ngIf="!_isLoading">
            <div *ngIf="_signInOutData.SIGNIN_TIME == '0:0'" class="status request">
              <div class="name">Please Sign In</div>
            </div>

            <div
              *ngIf="_signInOutData.SIGNIN_TIME && _signInOutData.SIGNIN_TIME != '0:0' && _signInOutData.SIGNOUT_TIME == '00:00'"
              class="status started">
              <div class="name">
                <mat-icon>play_circle</mat-icon> Signed In
              </div>
              <b class="time">{{_signInOutData.SIGNIN_TIME}}</b>
            </div>

            <div *ngIf="_signInOutData.SIGNIN_TIME && _signInOutData.SIGNOUT_TIME != '00:00'" class="status stopped">
              <div class="name">
                <mat-icon>stop_circle</mat-icon> Signed Out
              </div>
              <b class="time">{{_signInOutData.SIGNIN_TIME}} to {{_signInOutData.SIGNOUT_TIME}}</b>
            </div>
          </ng-container>



        </button>

        <mat-menu #signInOut="matMenu" xPosition="before">

          <button mat-menu-item *ngIf="_signInOutData.SIGNIN_TIME == '0:0'" (click)="onSignInOut('SIGNIN')">
            <mat-icon>play_circle</mat-icon>
            Sign In
          </button>
          <button mat-menu-item *ngIf="_signInOutData.SIGNIN_TIME != '0:0' && _signInOutData.SIGNOUT_TIME == '00:00'"
            (click)="onSignInOut( 'SIGNOUT') ">
            <mat-icon>stop_circle</mat-icon>
            Sign Out
          </button>


          <button mat-menu-item disabled
            *ngIf="_signInOutData.SIGNIN_TIME != '0:0' && _signInOutData.SIGNOUT_TIME != '00:00'">
            <mat-icon>stop_circle</mat-icon>
            Signed Out
          </button>

        </mat-menu>

      </ng-container>



      <button *ngIf="((_authService.authStatus | async).userRole != 'developer')" class="user-status-btn" mat-button>

        <div class="date">
          <div class="day">{{_currentDay}}</div>
          <div class="month-year">
            <div class="month">{{_currentMonth}}</div>
            <div class="year">{{_currentYear}}</div>
          </div>
        </div>



      </button>


    </div>
  </div>


  <div class="user-menu ">

    <button mat-icon-button class="user-nav-wrap-trigger" (click)="_toggleUserNav=!_toggleUserNav">
      <mat-icon>menu</mat-icon>
    </button>

    <button mat-icon-button [matMenuTriggerFor]="userMenu">
      <mat-icon>account_circle</mat-icon>
    </button>

    <mat-menu #userMenu="matMenu">
      <div class="user-banner ">
        <div *ngIf="(_authService.authStatus | async).userName != null" class="user-name-icon icon">
          {{(_authService.authStatus | async).userName[0]}}</div>
        <div class="name">{{(_authService.authStatus | async).userName}}</div>
        <div class="desg">{{(_authService.authStatus | async).userRole}}</div>
      </div>
      <button (click)="onLogout() " mat-menu-item>
        <mat-icon>logout</mat-icon> Logout
      </button>
    </mat-menu>
  </div>
</div>
