<div fxLayout="row" fxLayoutAlign="end top" fxLayoutGap="20px" fxLayoutWrap fxLayout.xs="column" class="app-login-inner">

    <!-- <div fxFlex="75%" class="hide-xs">
    <img src="/assets/img/home/banner.svg" alt="user" class="img-full">
  </div> -->

    <div fxFlex fxLayoutAlign="center top" fxLayout="column" class="login-card">
        <mat-card class="p-0">
            <mat-card-content>
                <div class="box-header" fxlayout="column" fxlayoutalign="center center">
                    <img class="seguro-logo" src="/assets/img/logo-login-dark.png" alt="seguro">
                </div>
                <mat-card-content class="box-content mat-card-content" fxlayout="column" fxlayoutalign="end center">
                    <mat-card class="box-content-inner mat-card-pad " fxlayout="column" fxlayoutalign="center center">
                        <form [formGroup]="loginForm" (ngSubmit)="login(loginForm)" fxLayout="column">
                            <div fxLayout="row" fxLayoutGap="10px" class="p-b-20">
                                <mat-form-field fxFlex appearance="outline">
                                    <mat-label>E-mail</mat-label>
                                    <input matInput formControlName="email">
                                    <mat-error *ngIf="loginForm.get('email').hasError('required')">
                                        E-mail is required
                                    </mat-error>
                                    <mat-error *ngIf="loginForm.get('email').hasError('email')">
                                        E-mail is not valid
                                    </mat-error>
                                    <mat-icon matSuffix>email</mat-icon>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutGap="10px" class="p-b-20">
                                <mat-form-field fxFlex appearance="outline">
                                    <mat-label>Password</mat-label>
                                    <input matInput type="password" formControlName="password">
                                    <mat-hint>Minimum 8 characters</mat-hint>
                                    <mat-error *ngIf="loginForm.get('password').hasError('required')">
                                        Password is required
                                    </mat-error>
                                    <mat-error *ngIf="loginForm.get('password').hasError('minlength')">
                                        Password is at least 8 characters long
                                    </mat-error>
                                    <mat-error *ngIf="loginForm.get('password').hasError('maxlength')">
                                        Password cannot be longer than 50 characters
                                    </mat-error>
                                    <mat-icon matSuffix>vpn_key</mat-icon>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column" fxLayoutGap="10px">
                                <button mat-raised-button type="submit" color="primary" [disabled]="loginForm.invalid">Login</button>
                                <div *ngIf="loginError" class="mat-caption error">{{loginError}}</div>
                            </div>
                        </form>
                        <mat-card-subtitle class="text-white1 p-t-20">
                            <br/>
                            <br/>If you do not have username / password, please send an email request to hr@segurotechnologies.com
                        </mat-card-subtitle>
                    </mat-card>
                </mat-card-content>
            </mat-card-content>
        </mat-card>
        <p class="text-center copyright"> © 2022
            <a href="https://seguroits.com/" target="_blank">Seguro ITS</a>
        </p>
    </div>

    <!-- <div class="hide-pc">
        <img src="/assets/img/home/banner.svg" alt="user" class="img-full">
    </div> -->

</div>
