import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs'

import { Role } from '../auth/auth-role.enum'
import { AuthService } from '../auth/auth.service'
import { PasswordValidation, RequiredTextValidation } from '../common/validations'
import { LoaderService } from '../services/loader.service'
import { UiService } from '../services/ui.service'

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: UntypedFormGroup
  loginError = ''
  status = false
  redirectUrl

  _authStatusSubscription: Subscription
  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private uiService: UiService,
    private loaderService: LoaderService
  ) {
    //this.authService.logout()
    this.loaderService.show()
    route.paramMap.subscribe((params) => (this.redirectUrl = params.get('redirectUrl')))
  }

  ngOnInit() {
    this._authStatusSubscription = this.authService.authStatus.subscribe((data) => {
      if (data.isAuthenticated) {
        this.uiService.showToast(`Welcome ` + data.userName + '-' + data.userRole)
        this.router.navigate([this.redirectUrl || this.homeRoutePerRole(data.userRole)])
      }
    })
    this.buildLoginForm()
  }

  buildLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: ['s@s', RequiredTextValidation],
      password: ['s', PasswordValidation],
    })
    this.loaderService.hide()
  }

  //remove async login to login
  login(submittedForm: UntypedFormGroup) {
    this.authService
      .login(submittedForm.value.email, submittedForm.value.password)
      .subscribe(
        (authStatus) => {
          this.loaderService.hide()
          if (authStatus.isAuthenticated) {
            this.uiService.showToast(
              `Welcome ` + authStatus.userName + '-' + authStatus.userRole
            )

            // this.router.navigate([
            //   this.redirectUrl || this.homeRoutePerRole(authStatus.userRole),
            // ])
          }
        },
        (error) => (this.loginError = error)
      )
  }

  homeRoutePerRole(role: Role) {
    switch (role) {
      case Role.Manager:
        return '/manager'
      case Role.Developer:
        return '/manager'
      case Role.Sales:
        return '/sales'
      default:
        alert(
          'Role ' +
            role +
            'not defined in login.component.ts, redirecting to /user/profile'
        )
        return '/manager'
    }
  }

  ngOnDestroy() {
    this._authStatusSubscription.unsubscribe()
  }
}
